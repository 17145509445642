import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { inMemoryScrollingFeature, routes } from './app.routes';
import { QuillModule } from 'ngx-quill';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '@shared/utils/http-loader-factory.utils';
import { LanguageEnum } from '@shared/models/language.model';
import {
  HttpClient,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { authInterceptor } from '@interceptors/auth-interceptor';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import { ToastyEnum } from '@models/toasty.model';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(routes, inMemoryScrollingFeature),
    importProvidersFrom(
      BrowserAnimationsModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        },
        defaultLanguage: LanguageEnum.English,
        useDefaultLang: true
      }),
      QuillModule.forRoot()
    ),
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        panelClass: ToastyEnum.Info,
        duration: 3000,
        verticalPosition: 'top'
      } as MatSnackBarConfig
    }
  ]
};
