import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  inject,
  OnInit
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { setRealVh } from '@utils/screen.utils';
import { AuthStore } from '@app/store/auth/auth.store';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from '@models/language.model';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  authStore = inject(AuthStore);

  constructor(private translateService: TranslateService) {
    this.translateService.use(LanguageEnum.English);
  }

  ngOnInit() {
    setRealVh();
  }

  @HostListener('window:resize')
  onResize() {
    setRealVh();
  }
}
